
import './App.css';
import { Suspense, lazy } from 'react';
import './Bootstrap/css/bootstrap.min.css'
function App() {
 
  const Homepage = lazy(() => import("./Components/Homepage"))
  return (
    <div className="App">
      <Suspense fallback={() => <div>Loading ....</div>}>
        <Homepage />
      </Suspense>

    </div>
  );
}

export default App;
